import { classNames } from '@utils';
import PropTypes from 'prop-types';
import styles from './icon-card.module.scss';
import React from 'react';

const IconCard = (props) => {
	return (
		<div
			className={classNames(props.className, styles.card)}
			style={{
				height: props.height,
				width: props.width
			}}
		>
			<img className={styles.image} src={props.icon} alt="" />
			<h3 className={styles.title}>{props.title}</h3>
			{props.description && (
				<span
					className={styles.description}
					dangerouslySetInnerHTML={{
						__html: props.description
					}}
				/>
			)}
			{props.items && (
				<ul>
					{props.items.map((item) => (
						<li>{item}</li>
					))}{' '}
				</ul>
			)}
		</div>
	);
};

IconCard.propTypes = {
	height: PropTypes.string
};

export default IconCard;
