import React from 'react';
import PropTypes from 'prop-types';
import Heading from '@components/heading';
import styles from './heading-nearshore.module.scss';

const HeadingNearshore = (props) => {
	const { title } = props;

	return (
		<div className={styles.container}>
			<h1 className={styles.title}>{title}</h1>
		</div>
	);
};

export default HeadingNearshore;
